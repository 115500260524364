import React, { useState, useContext, ReactNode } from "react";
import { LoadingShim } from "@narmi/design_system";
import Portal from "../Portal";

interface LoadingContextProps {
  children: ReactNode;
}

interface LoadingContextState {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const initialLoadingContextState: LoadingContextState = {
  isLoading: false,
  setIsLoading: () => {},
};

const LoadingContext = React.createContext<LoadingContextState>(
  initialLoadingContextState
);
export const useLoadingContext = () => useContext(LoadingContext);

const LoadingPortal = (): JSX.Element => {
  const { isLoading } = useLoadingContext();
  return (
    <Portal rootId="loading-container">
      {isLoading && (
        <LoadingShim isLoading={isLoading}>
          <div></div>
        </LoadingShim>
      )}
    </Portal>
  );
};

/**
 *
 * Provider for a LoadingContext
 * Also includes the LoadingPortal itself
 *
 */
export const LoadingContextProvider = ({
  children,
}: LoadingContextProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      <LoadingPortal />
      {children}
    </LoadingContext.Provider>
  );
};

export default LoadingContext;
